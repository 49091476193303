import {ActivatedRoute, Router} from '@angular/router';
import {Meta, Title} from '@angular/platform-browser';
import {environment} from '@env/environment';
import {_seoContent, ISeoData, ISeoDataContainer, ISeoFaq} from '@app/marketplace/seo-content/utils';
import {Subscription} from 'rxjs';

export class SeoContent {
  sectionId = '';
  title = '';
  description = '';
  keywords = '';
  h1 = '';
  src_section = '';
  subtitle = '';
  faqs: ISeoFaq[] = [];
  container: ISeoDataContainer = null;
  seoRoute: ActivatedRoute;
  seoMetaService: Meta;
  seoTitleService: Title;
  seoRouter: Router;
  constructor(route: ActivatedRoute,
              metaService: Meta,
              titleService: Title,
              router: Router) {
    this.seoRoute = route;
    this.seoMetaService = metaService;
    this.seoTitleService = titleService;
    this.seoRouter = router;
  }

  loadPrerender() {
    let metas: ISeoData = null;
    this.seoRoute.params.subscribe(data => {
      const hasOffers = this.seoRouter.url.includes('ofertas-descuentos-tienda-envios-cuba');
      if (data.id || hasOffers) {
        metas = _seoContent.find(x => data.id === x.sectionId || data.id === x.categoryCode || (hasOffers && x.sectionId == 'ofertas-descuentos-tienda-envios-cuba'));
        if (metas) {
          this.sectionId = metas.sectionId;
          this.title = metas.title;
          this.description = metas.description;
          this.keywords = metas.keywords;
          this.h1 = metas.h1;
          this.src_section = metas.hasBanner ? `assets/market/categorias/${metas.sectionId}` : `assets/market/categorias/combos`;
          this.subtitle = metas.container?.subtitle?.text || '';
          this.container = metas.container || null;
          this.faqs = metas.faqs || [];
        }
      }
      this.updateMetaTags(this.title, this.description);
    });
    return metas;
  }

  updateMetaTags(title: string, description: string) {
    this.seoMetaService.updateTag({
      name: 'description',
      content: description,
    });
    this.seoMetaService.updateTag({name: 'keywords', content: this.keywords});
    this.seoMetaService.updateTag({name: 'robots', content: 'all'});
    this.seoMetaService.updateTag({property: 'og:title', content: title});
    this.seoMetaService.updateTag({
      property: 'og:description',
      content: description,
    });
    this.seoMetaService.updateTag({
      property: 'og:url',
      content: `${environment.host}${this.seoRouter.url}`,
    });
    this.seoTitleService.setTitle(title);
  }
}
